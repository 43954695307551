import widgetSDKAI from './WidgetSDKUtils';
import React from 'react';

import './Viewer.css';


// https://signavio-sac-ent.rel.canary.eu10.projectorca.cloud/sap/fpa/ui/tenants/40a7b/bo/story/7E0050581E24D39CE10000000A784278
class SimpleApp extends React.Component {
  componentDidMount() {
    widgetSDKAI("https://signavio-sac-ent.rel.canary.eu10.projectorca.cloud/sap/fpa/ui/", "D2D00107078681C51451B0C1B45FC6A9", "Chart_3", "Story1_Page1Widget1");
    widgetSDKAI("https://signavio-sac-ent.rel.canary.eu10.projectorca.cloud/sap/fpa/ui/", "7E0050581E24D39CE10000000A784278", "Chart_1", "Story1_Page1Widget2");
    widgetSDKAI("https://osd6jeh9nkdyht8sxx8ude1.orcastarkiller.canary.eu10.projectorca.cloud/sap/fpa/ui/", "53080081AFEC0A1609D576DF0F06E63F", "Chart_1", "Story2_Page1Widget1");
    widgetSDKAI("https://osd6jeh9nkdyht8sxx8ude1.orcastarkiller.canary.eu10.projectorca.cloud/sap/fpa/ui/", "53080081AFEC0A1609D576DF0F06E63F", "Chart_2", "Story2_Page1Widget2");
  }
  render() {
    return (
     <div className='container'>  
        <div className="StoryFrame">
          <p id='StoryCategoryLabel'> Employment Benefit</p>

          <div id="Story1_Page1Widget1" className='layoutOptions' />
          <div id="Story1_Page1Widget2" className='layoutOptions' />
        </div>
        <div className="StoryFrame">
          <p id='StoryCategoryLabel'> Job Employment Status</p>

          <div id="Story2_Page1Widget1" className='layoutOptions' />
          <div id="Story2_Page1Widget2" className='layoutOptions' />
        </div>
      </div>
    );
  }
}

export default SimpleApp;
