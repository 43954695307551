/* global sap */
function widgetSDKAI(tenantURL, storyID, widgteID, domID,options) {
sap.sac.api.widget.renderWidget(domID, tenantURL, storyID, widgteID, options,
    {
      renderComplete: {
        onSuccess: () => { console.log(`Loaded Succesfully widgetID ${widgteID} in ${domID} for story ${storyID}`) },
        onFailure: () => { console.log(`Failed to Load widgetID ${widgteID} in ${domID} for story${storyID}`) }
      }
    })
}

export default widgetSDKAI;
