import React from 'react';
import { useState } from 'react';
import EmbeddedLiteApp from './EmbeddedLiteApp';
import {
    ComboBox, ComboBoxItem, Text
} from '@ui5/webcomponents-react';
import SimpleApp from './SimpleApp';
import './Viewer.css';


const App = () => {
    const [selectedOption, setSelectedOption] = useState("");

    const handleChange = (event) => {
        setSelectedOption(event.srcElement.value);
    };

    return (
        <>
            <Text className="heading"> Composable BI Analytics Usecase for OEM's</Text> <br />
            <div className="usecaseComboBox">
                <ComboBox
                    onSelectionChange={handleChange}
                    valueState="None"
                    placeholder="Select the Usecase"
                    
                >
                    <ComboBoxItem text="Simple Tenant Usecase" />
                    <ComboBoxItem text="Embedded Lite Tenant Usecase" />
                </ComboBox>
                {selectedOption === "Simple Tenant Usecase" && <SimpleApp />}
                {selectedOption === "Embedded Lite Tenant Usecase" && <EmbeddedLiteApp />}
            </div>
        </>
    );

};

export default App;
