import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {
  ThemeProvider
} from '@ui5/webcomponents-react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider>
      <App />
    </ThemeProvider>
);

reportWebVitals();
