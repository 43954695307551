import React from 'react';
import { useRef } from "react";

import {
    ComboBox, ComboBoxItem, Dialog
} from '@ui5/webcomponents-react';

import widgetSDKAI from './WidgetSDKUtils';
import './Viewer.css';


const EmbeddedLiteApp = () => {
    const dialogRef = useRef(null);
    const handleClose = () => {
        dialogRef.current.close();
    };
    const handleSelectionChange = (event, dom) => {
        handleClose();
        let options = {
            tenant: {
                hostPrefix: ""
            }
        };
        switch (event.srcElement.value) {
            case "Redbull":
                options.tenant.hostPrefix = "oisfpa82"
                break;
            case "Shell":
                options.tenant.hostPrefix = "ois-admin"
                break;
            default:
                console.log("No customer selected");
        }
        widgetSDKAI("https://osyym632k56jdeyz992rrgn.orcastarkiller.canary.eu10.projectorca.cloud/sap/fpa/ui/", "D3180C85C23CDFEB73AAC5FCA50EC415", "Chart_2", "Story1_Page1Widget1", options);
        widgetSDKAI("https://osyym632k56jdeyz992rrgn.orcastarkiller.canary.eu10.projectorca.cloud/sap/fpa/ui/", "D3180C85C23CDFEB73AAC5FCA50EC415", "Chart_5", "Story1_Page1Widget3", options);

    }
    return (
        <div className='container'>  
            <Dialog
                headerText="Select the Customer"
                open={true}
                ref={dialogRef}>

                <ComboBox id="hostOEM" placeholder="Enter value" onChange={handleSelectionChange.bind(this)}>
                    <ComboBoxItem key={0} text="Redbull" />;
                    <ComboBoxItem key={1} text="Shell" />;
                </ComboBox>
            </Dialog>


            <div>
                <div className="StoryFrame">
                    <p id='StoryCategoryLabel'> Quantity Sold </p>

                    <div id="Story1_Page1Widget1" className='layoutOptions' />
                    <div id="Story1_Page1Widget2" className='layoutOptions' />
                </div>
                <div className="StoryFrame">
                    <p id='StoryCategoryLabel'> Product Sales</p>

                    <div id="Story1_Page1Widget3" className='layoutOptions' />
                    <div id="Story1_Page1Widget4" className='layoutOptions' />
                </div>
            </div>
        </div>
    );
};

export default EmbeddedLiteApp;
